<template>
  <el-select
    :value="localValue"
    :size="size"
    :multiple="multiple"
    :disabled="disabled"
    clearable
    :placeholder="placeholder"
    @change="selected"
  >
    <el-option
      v-for="index in 12"
      :key="index"
      :label="index"
      :value="index"
    >
    </el-option>
  </el-select>

</template>

<script>

export default {
  name: "card-period-select",
  components: {},
  props: {
    value: {},
    size: {type: String, default: '' },
    disabled: {type: Boolean, default: false },
    multiple: {type: Boolean, default: true },
    placeholder: {type: String, default: 'Номер периода' },
  },

  data() {
    return {
      localValue: this.value,
    }
  },
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {
    selected(value) {
      this.localValue = value;
      this.$emit('input', value);
      this.$emit('change', value);
    }
  },

}
</script>

<style scoped>

</style>